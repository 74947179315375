import { invoiceTabValues } from './components/invoice-detail-presentational';
import Actions from './invoiceDetailAction';

export const initState = {
	invoiceStatementDownloadLoading: false,
	invoiceStatementMsg: null,

	invoiceReceiptLoading: false,
	invoiceReceiptUrl: null,

	invoiceHistoryLoading: false,
	invoiceReceipts: [],

	saveGroupInvoiceLoading: false,
	saveGroupInvoiceMsg: null,

	getGroupInvoiceLoading: false,
	invoiceGroup: [],
	allInvoices: [],
	allInvoicesLoading: false,
	downloadInvoicesLoading: false,
};

const invoiceInitPayloadState = {
	InvoiceReceiptFile: null,
	Amount: 0,
	PaymentDate: '',
	PaymentTime: '',
	ReceiptType: invoiceTabValues.BANK_TRANSFER_VALUE,
	ATMDigit: null,
	ChequeNumber: null,
};

const invoiceDetailReducer = (state = initState, action) => {
	switch (action.type) {
		case Actions.types.SAVE_GROUP_INVOICE_START: {
			return {
				...state,
				saveGroupInvoiceLoading: true,
				saveGroupInvoiceMsg: null,
			};
		}

		case Actions.types.SAVE_GROUP_INVOICE_SUCCESS: {
			return {
				...state,
				saveGroupInvoiceLoading: false,
				saveGroupInvoiceMsg: action.message,
			};
		}

		case Actions.types.SAVE_GROUP_INVOICE_FAIL: {
			return {
				...state,
				saveGroupInvoiceLoading: false,
				saveGroupInvoiceMsg: null,
			};
		}

		case Actions.types.DOWNLOAD_COMPANY_STATEMENT_START: {
			return {
				...state,
				invoiceStatementDownloadLoading: true,
				invoiceStatementMsg: null,
			};
		}

		case Actions.types.DOWNLOAD_COMPANY_STATEMENT_SUCCESS: {
			return {
				...state,
				invoiceStatementDownloadLoading: false,
				invoiceStatementMsg: action.response,
			};
		}

		case Actions.types.DOWNLOAD_COMPANY_STATEMENT_FAIL: {
			return {
				...state,
				invoiceStatementDownloadLoading: false,
				invoiceStatementMsg: null,
			};
		}

		case Actions.types.INVOICE_FORM_VALUE_HANDLER: {
			return {
				...state,
				invoiceFormValues: action.payloadState ? action.payloadState : invoiceInitPayloadState,
			};
		}

		case Actions.types.INVOICE_HISTORY_START: {
			return {
				...state,
				invoiceHistoryLoading: true,
				invoiceReceipts: [],
			};
		}

		case Actions.types.INVOICE_HISTORY_SUCCESS: {
			return {
				...state,
				invoiceHistoryLoading: false,
				invoiceReceipts: action.data,
			};
		}
		case Actions.types.INVOICE_HISTORY_FAIL: {
			return {
				...state,
				invoiceHistoryLoading: false,
				invoiceReceipts: [],
			};
		}

		case Actions.types.INVOICE_RECEIPT_DOWNLOAD_START: {
			return {
				...state,
				invoiceReceiptLoading: true,
				invoiceReceiptUrl: null,
			};
		}

		case Actions.types.INVOICE_RECEIPT_DOWNLOAD_SUCCESS: {
			return {
				...state,
				invoiceReceiptLoading: false,
				invoiceReceiptUrl: action.data,
			};
		}
		case Actions.types.INVOICE_RECEIPT_DOWNLOAD_FAIL: {
			return {
				...state,
				invoiceReceiptLoading: false,
				invoiceReceiptUrl: null,
			};
		}

		case Actions.types.GET_GROUP_INVOICE_START: {
			return {
				...state,
				getGroupInvoiceLoading: true,
				invoiceGroup: [],
			};
		}

		case Actions.types.GET_GROUP_INVOICE_SUCCESS: {
			return {
				...state,
				getGroupInvoiceLoading: false,
				invoiceGroup: action.data,
			};
		}
		case Actions.types.GET_GROUP_INVOICE_FAIL: {
			return {
				...state,
				getGroupInvoiceLoading: false,
				invoiceGroup: [],
			};
		}
		case Actions.types.VIEW_ALL_INVOICES: {
			return {
				...state,
				allInvoices: action.data,
			};
		}
		case Actions.types.VIEW_ALL_INVOICES_LOADING: {
			return {
				...state,
				allInvoicesLoading: action.loading,
			};
		}
		case Actions.types.DOWNLOAD_INVOICE_LOADING: {
			return {
				...state,
				downloadInvoicesLoading: action.loading,
			};
		}

		default:
			return state;
	}
};

export default invoiceDetailReducer;
