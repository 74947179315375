import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Form, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	amountWithNoCommas,
	arrayToObject,
	buttonDisable,
	forEnablingButton,
	isEmailValid,
	isOnlySpecialCharacter,
	removeNullFromObject,
	viewDateFormat,
} from '@helpers';
import action from '@pages/Leave-Management/leaves-status/Component/employeeHistorySaga/action';
import profileAction from '@pages/payroll-plus/profile-action';
import payRollRunAction from '@pages/payroll-run/action';
import payrollSettingAction from '@pages/payroll-plus/payroll-setting-action';
import DocumentAction from '@pages/employees/manage-employees/view-employee/components/Emp-Documents/action';
import { APP_VARIABLES, DATE_FORMAT, managePayrollClaim } from '@constants/app-constants';
import EmployeeService from 'src/services/employee-service';
import RoutingService from 'src/services/routing-service';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import ClevertapReact from 'src/utils/clever-tap';
import UserService from 'src/services/user-service';
import { successToast } from 'src/utils/toast';
import ViewEmployeePresentational from './view-employee-presentational';

const ViewEmployeeFunctional = () => {
	const [empDocumentForm] = Form.useForm();
	const userAPI = new UserService();
	let employeeApi = new EmployeeService();
	let routingApi = new RoutingService();
	const history = useHistory();
	const dispatch = useDispatch();
	const [employeeDetails, setEmployeeDetails] = useState({});
	const [isShowLoader, setIsShowLoader] = useState(false);
	let [bankOptions, setBankOptions] = useState([]);
	let [employeeRoles, setEmpRoles] = useState([]);
	let [wpsDetails, setWpsDetails] = useState({});
	let [employeeDocument, setEmployeeDocument] = useState({});
	let [isDataLoading, setIsDataLoading] = useState(false);
	let [employeeProfilePopup, setEmployeeProfilePopup] = useState({
		isPopupVisible: false,
		popupMessage: '',
		popupSubMessage: '',
		okText: 'Yes',
		cancelText: 'No',
		param: '',
	});
	const [leaveTypelist, setLeaveTypelist] = useState([]);
	let [data, setData] = useState({});
	const [employeeHistory, setEmployeeHistory] = useState([]);
	const [sampleData, setSampleData] = useState([]);
	const [navigateTabCheck, setNavigateTabCheck] = useState({
		isTabClicked: false,
		tabKey: '',
		selectedTab: history?.location?.state?.selectedTab,
		currentTab: '',
	});
	const [modalOpen, setModalOpen] = useState(false);
	const [cardloading, setcardloading] = useState(false);
	const [replacementCardMessage, setReplacementCardMessage] = useState(null);
	const [confirmationModelOpen, setConfirmationModelOpen] = useState(false);
	const [popupVisible, setPopupVisible] = useState(false);
	const [popupContent, setPopupContent] = useState({
		title: '',
		content: '',
		onOkayButton: 'Ok'
	});
	const [isAllowtoNavigate, setisAllowtoNavigate] = useState(false);
	const [invitePortalEmailId, setInvitePortalEmailId] = useState('');
	const [employeeAllowance, setEmpolyeeAllowance] = useState();
	const [empolyeeSalary, setEmpolyeeSalary] = useState();
	const [termiation, setTerminationType] = useState();
	const [terminationId, setTerminationId] = useState();
	const [overTimes, setOverTimes] = useState();
	const [month, setmonth] = useState();
	const [year, setyear] = useState();
	const [savePayrollValue, setSaveValue] = useState();
	const [gratutiycal, setGratuityCal] = useState(0);
	const [employeeDetailState, setEmployeeDetailState] = useState({
		nationality: '',
		dob: '',
		mobileNo: '',
		emailId: '',
		cardSerialNumber: '',
		cardIssued: '',
		accountNo: '',
		wpsPersonid: '',
		wpsEstablishmentId: '',
		iban: '',
		bankName: '',
		labourCardNo: '',
	});
	const [notification, setNotification] = useState(false);
	const [payrollDetails, setPayrollDetails] = useState({
		employeeId: '',
		joiningDate: '',
		endDate: '',
		contractTypeId: '',
		contractType: '',
		departmentId: '',
		department: '',
		designation: '',
		salary: '',
		id: '',
	});
	const [addAllowance, setAddAllowance] = useState([
		{
			allowanceId: '',
			allowance: '',
			aed: 0,
			index: 0,
			id: '',
		},
	]);
	const [isEnabled, setIsEnabled] = useState({
		Limited: false,
		UnLimited: false,
		allowance: false,
		savePopUp: false,
		closePopUp: false,
		disAllAble: false,
		disAedAble: false,
	});
	const [buttonFlag, setButtonFlag] = useState(false);
	const [hasErrors, setHasErrors] = useState(true);
	const [empDocState, setEmpDocState] = useState({});
	const [backupData, setBackupData] = useState({});
	const [additions, setAdditions] = useState([]);
	const [deductions, setDeductions] = useState([]);
	const [runEmployeeAllowanceList, setRunEmployeeAllowanceList] = useState([]);
	const [gratuity, setGratuity] = useState(false);
	const [loading, setloading] = useState(false);
	const [expiryDates, setExpiryDates] = useState({
		labour: false,
		emirates: false,
		visa: false,
		passport: false,
	});
	const [imageData, setImageData] = useState({
		labourCardScan: '',
		emiratesFrontScan: '',
		emiratesBackScan: '',
		visaScan: '',
		passportScan: '',
	});
	const [leavingDate, setLeavingDate] = useState();

	const leavingDateGlobalState = useSelector((state) => state?.PayRollRunReducer?.payRollRun?.employeeContract?.leavingDate);
	const _gratuity = useSelector((state) => state?.PayRollRunReducer?.payRollRun?.gratuity);
	const { salaryTypeList } = useSelector((state) => state?.PayRollRunReducer?.salaryPaidStatus) || {};
	useEffect(() => {
		if (leavingDateGlobalState) {
			setLeavingDate(leavingDateGlobalState);
		}
	}, [leavingDateGlobalState]);

	useEffect(() => {
		if (_gratuity && _gratuity.value) {
			setGratuityCal(_gratuity.value);
			setGratuity(true);
		} else {
			setGratuityCal(0);
			setGratuity(false);
		}
	}, [_gratuity]);

	useEffect(() => {
		if (typeof employeeDetails?.emailId === 'string') {
			setInvitePortalEmailId(employeeDetails?.emailId);
		}
	}, [employeeDetails?.emailId]);

	const employeeProfileCard = [
		{
			id: 0,
			name: 'Order Card Replacement',
			icon: not_focus_icons.orderCard,
			param: 'cardOrder',
			enable: true,
			cleverTapEventName: 'ReplacementCard',
		},
		{
			id: 1,
			name: 'ATM Dispute',
			icon: not_focus_icons.atmDispute,
			param: 'atmDispute',
			enable: true,
			cleverTapEventName: 'ATMDispute',
		},
		{
			id: 2,
			name: 'Request Card Statement',
			icon: not_focus_icons.requestCard,
			param: 'requestCard',
			enable: true,
			cleverTapEventName: 'RequestCardStatement',
		},
		{
			id: 3,
			name: 'Change C3Pay to Bank Account',
			icon: not_focus_icons.c3payToBank,
			param: 'c3payToBank',
			enable: true,
			cleverTapEventName: 'ChangetoBank Account',
		},
	];
	// api to get employee detail
	useEffect(() => {
		getEmployeeById(history?.location.state?.tableData?.employeeId);
		setNavigateTabCheck({ ...navigateTabCheck, currentTab: history?.location?.state?.selectedTab });
		fetchRoutingDetails();
	}, []);
	useEffect(() => {
		!history?.location?.state?.mainProfile && employeeDetails?.userId && getEmployeeRoles(employeeDetails?.userId);
	}, [employeeDetails?.userId]);
	const getEmployeeRoles = (username) => {
		userAPI
			.getUserClaims(username)
			.then((response) => {
				let roleArr = [];
				response &&
					response.data.roles.map((data) => {
						if (['Payroll', 'HR', 'Manage Roles'].includes(data?.name)) {
							roleArr.push(data?.name);
						}
					});
				setEmpRoles([...roleArr]);
			})
			.catch((error) => {
				setEmpRoles([]);
			});
	};
	async function getEmployeeById(empId) {
		setIsDataLoading(true);
		try {
			let response = await employeeApi.getEmployeeById(empId);
			if (response && response?.data) {
				let finalResp = { ...response?.data?.employee };
				finalResp.dateOfBirth = viewDateFormat(finalResp?.dateOfBirth);
				finalResp.wpsPersonid = finalResp?.wpsPersonid || '';
				finalResp.wpsEstablishmentId = finalResp?.wpsEstablishmentId || '';
				finalResp.emiratedIdExpiryDate = finalResp?.emiratedIdExpiryDate && moment(finalResp?.emiratedIdExpiryDate).format('DD/MM/YYYY');
				await setTimeout(() => {
					setEmployeeDetails(finalResp);
				});
				setWpsDetails({
					header: 'WPS Details',
					cardNumber: `Labour Card Number: ${finalResp?.labourCardNo || ''}`,
					personId: `WPS Person ID: ${finalResp?.wpsPersonid}`,
					establishmentID: `WPS Establishment ID: ${finalResp?.wpsEstablishmentId}`,
				});
				setEmployeeDocument({
					EmployeeDocuments: 'Employee Documents',
					PassportNumber: `Passport Number: ${finalResp?.passportNumber}`,
					Emirates_ID: finalResp?.emiratesId,
					EmiratesIDexpiry: `Emirates ID expiry: ${finalResp?.emiratesIdExpiryDate}`,
					EmiratesDate: finalResp?.emiratesIdExpiryDate,
					EmiratesId: 'Emirates ID',
				});
				getEmployeeHistory(finalResp);
				setData(finalResp);
			}
		} catch (err) {}
		setIsDataLoading(false);
	}
	const getEmployeeHistory = (employee) => {
		if (employee.id) {
			employeeApi
				.getEmployeesHistory(employee.id)
				.then((res) => {
					setEmployeeHistory(
						res?.data?.employeeSalaries
							? res?.data?.employeeSalaries?.map((emp, i) => {
									return {
										...emp,
										serialNo: i + 1,
										employeCode: history?.location?.state?.tableData?.employeeCode,
										monthYear: `${emp?.month}/${emp?.year}`,
										processedDateModified: moment(emp?.processedDate).format('DD-MM-YYYY'),
									};
							  })
							: []
					);
				})
				.catch((err) => {
					setEmployeeHistory([
						{
							employeeId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
							month: 'July',
							year: 2020,
							salary: '1,892,719.00',
							variablePay: 0,
							daysOnLeave: 0,
							isEndOfService: true,
						},
						{
							employeeId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
							month: 'July',
							year: 2020,
							salary: '1,892,719.00',
							variablePay: 0,
							daysOnLeave: 0,
							isEndOfService: true,
						},
					]);
				});
		}
	};
	async function fetchRoutingDetails() {
		try {
			let response = await routingApi.getRouting();
			if (response && response?.data && response?.data?.routings) {
				let bankOptions = response?.data?.routings?.map((bank) => {
					bank.label = bank?.bankName;
					bank.value = bank?.routingCode;
					return bank;
				});
				setBankOptions(bankOptions);
			}
		} catch (err) {}
	}
	const isLeaveEdited = useMemo(() => JSON.stringify(sampleData) === JSON.stringify(leaveTypelist), [sampleData]);
	let empDOB = employeeDetailState?.dob?.split('/').reverse().join('-');
	let currentDate = moment().format('YYYY-MM-DD');
	let empProfileButtonDisable =
		empDOB > currentDate ||
		employeeDetailState?.wpsPersonid?.length !== 14 ||
		employeeDetailState?.wpsEstablishmentId?.length !== 13 ||
		employeeDetailState?.labourCardNo?.length < 5 ||
		employeeDetailState?.labourCardNo?.length > 9 ||
		employeeDetailState?.bankAccountNo?.length > 34;
	const isEmailValided = employeeDetailState?.emailId ? isEmailValid(employeeDetailState?.emailId) : true;
	const profileSaveButtonEnable = forEnablingButton(employeeDetails, employeeDetailState);

	const handleProfileSave = () => {
		const isPersonalInfoChanged =
			employeeDetailState?.nationality === employeeDetails?.nationality &&
			employeeDetailState?.emailId === employeeDetails?.emailId &&
			employeeDetailState?.dob === moment(employeeDetails?.dateOfBirth, 'DD/MM/YYYY').format(DATE_FORMAT?.DD_MM_YYYY);
		const isAccountInfoChanged =
			employeeDetailState?.iban === employeeDetails?.bankAccountNo &&
			employeeDetailState?.bankName === employeeDetails?.bankRoutingCode &&
			employeeDetailState?.wpsPersonid === employeeDetails?.wpsPersonid &&
			employeeDetailState?.wpsEstablishmentId === employeeDetails?.wpsEstablishmentId &&
			employeeDetailState?.labourCardNo === employeeDetails?.labourCardNo;

		!isPersonalInfoChanged && profileUpdatePersonalInfo();
		!isAccountInfoChanged && profileUpdateAccountInfo();
		!isLeaveEdited && handleLeaveDaysSave();
	};
	const profileUpdatePersonalInfo = async () => {
		setIsShowLoader(true);
		try {
			let payload = {
				firstName: employeeDetails?.firstName,
				lastName: employeeDetails?.lastName,
				nationality: employeeDetailState?.nationality,
				dateOfBirth: employeeDetailState?.dob?.split('/').reverse().join('-'),
				mobileNumber: employeeDetails?.mobileNo,
				emailId: employeeDetailState?.emailId,
			};
			let response = await employeeApi.updateEmployee(payload, employeeDetails?.id);
			if (response && response.data && typeof response.data.message === 'string') {
				throw new Error(response.data.message);
			}
			if (response && response?.status === 200) {
				setIsShowLoader(false);
				getEmployeeById(employeeDetails?.id);
			}
		} catch (err) {
			setIsShowLoader(false);
			message.error(err.message, 7);
		}
	};
	async function profileUpdateAccountInfo() {
		setIsShowLoader(true);
		const empId = employeeDetails?.id;
		try {
			let payload = {
				iBanNumber: employeeDetailState?.iban,
				bankCode: employeeDetailState?.bankName,
				wpsPersonId: employeeDetailState?.wpsPersonid,
				wpsEstablishmentId: employeeDetailState?.wpsEstablishmentId,
				labourCardNumber: employeeDetailState?.labourCardNo,
			};
			let response = await employeeApi.updateBank(payload, empId);
			if (response && response.data && typeof response.data.message === 'string') {
				throw new Error(response.data.message);
			}
			if (response && response?.status === 200) {
				setIsShowLoader(false);
				getEmployeeById(employeeDetails?.id);
			}
		} catch (err) {
			message.error(err.message, 7);
			setIsShowLoader(false);
		}
	}

	const handleLeaveDaysSave = () => {
		ClevertapReact.event('Leave _profile_editbalances');
		const payload = sampleData?.map((dta) => {
			return {
				leaveBalanceId: dta?.LeaveBalanceId || '',
				leaveTypeId: dta?.LeaveTypeId,
				leaveTypeName: dta?.leaveTypeName,
				balance: dta?.Days?.replace(/[^0-9-]/g, ''),
			};
		});
		dispatch(action.creators.saveLeaveTypeStart({ employeeId: employeeDetails?.employeeId, employeeLeaveBalance: payload }));
		getEmployeeById(employeeDetails?.id);
	};

	// card order replacement
	const getDeleterole = async () => {
		try {
			setcardloading(true);
			let response = await employeeApi.replacementCard({}, employeeDetails?.id);
			setcardloading(false);

			ClevertapReact.event('Request_ReplacementCard', {
				employee_id: employeeDetails?.employeeId,
				employee_name: employeeDetails?.employeeName,
			});

			if (response && response.status === 200) {
				setModalOpen(false);
				let responseMessage = response.data;
				let splicedString = responseMessage.slice(0, 60);

				let sampleString = 'The last replacement card for this employee was delivered on';
				if (splicedString === sampleString) {
					// show popup with past message
					setReplacementCardMessage(responseMessage);
					setConfirmationModelOpen(true);
				} else {
					//show popup with
					if (response.data === '') {
						setisAllowtoNavigate(true);
					} else {
						setisAllowtoNavigate(false);
					}
					setPopupVisible(true);
					setPopupContent({
						title: null,
						content: response.data ? response.data : 'Replacement card requested successfully',
					});
				}
			}
		} catch (err) {
			setisAllowtoNavigate(false);
			setcardloading(false);

			setModalOpen(false);
			setPopupVisible(true);
			setPopupContent({
				title: null,
				content: 'Something went wrong'
			});
		}
	};
	const confirmationsRequest = async () => {
		setcardloading(true);
		try {
			let response = await employeeApi.replacementCard({ confirmation: 'yes' }, employeeDetails?.id);
			setcardloading(false);
			//if responces has some message show popup
			if (response && response.status === 200) {
				// show normal popup
				if (response.data == '') {
					setisAllowtoNavigate(true);
				} else {
					setisAllowtoNavigate(false);
				}
				setConfirmationModelOpen(false);
				setPopupVisible(true);
				setPopupContent({
					title: null,
					content: response.data ? response.data : 'Replacement card requested successfully',
				});
			}
		} catch (err) {
			setisAllowtoNavigate(false);
			setPopupVisible(true);
			setPopupContent({
				title: null,
				content: 'Something went wrong',
			});
		}
	};
	const closePopup = () => {
		setPopupVisible(false);
		if (isAllowtoNavigate) {
			history.push({ pathname: '/employees/nextEmployee_step', state: { ...history?.location.state } });
		}
	};

	// setting loading state for submit
	const saveDocumentLoading = useSelector((state) => state?.individualData?.uploadDataLoading);
	const savePayrollSettingLoading = useSelector((state) => state?.payrollReducer?.isPayrollSubmitLoading);
	useEffect(() => {
		setIsShowLoader(saveDocumentLoading || savePayrollSettingLoading);
	}, [saveDocumentLoading, savePayrollSettingLoading]);

	// payroll profile tab
	const handleC3PayToBankAccount = (param, name) => {
		if (param === 'save') {
			switch (name) {
				case 'c3PatToBank':
					history.push({
						pathname: '/cardholder/c3paynumber',
						state: { employeeId: employeeDetails?.id, employeeName: employeeDetails?.name },
					});
					break;
				case 'deleteEmployee':
					deleteEmployee();
					break;
				default:
					break;
			}
		} else {
			setEmployeeProfilePopup({ ...employeeProfilePopup, isPopupVisible: false, popupMessage: '', popupSubMessage: '' });
		}
	};
	let userLocation = useSelector((state) => state?.sharedStates?.corporateIds);
	let employeeCardAddress = Object?.assign(
		{},
		...(userLocation || [])?.filter((corporate) => corporate?.corporateId === localStorage.getItem(APP_VARIABLES.CORPORATE_ID))
	);
	function deleteEmployee() {
		setloading(true);
		employeeApi
			.deleteManageEmployees([employeeDetails?.employeeId])
			.then((response) => {
				setEmployeeProfilePopup({ ...employeeProfilePopup, isPopupVisible: false, popupMessage: '', popupSubMessage: '' });
				setloading(false);
				history.goBack();
				if (response && response?.message) {
					successToast({
						msg: response?.message,
						icon: 'Error',
					});
				} else {
					successToast({
						msg: `Employee removed successfully`,
						icon: 'Check',
					});
				}
			})
			.catch((err) => {
				setEmployeeProfilePopup({ ...employeeProfilePopup, isPopupVisible: false, popupMessage: '', popupSubMessage: '' });
				setloading(false);
			});
	}
	const afterOpenModal = () => setModalOpen(false);

	const afterConfirmationOpenModal = () => setConfirmationModelOpen(false);

	const handleInviteEmployeeToPortal = () => {
		const payload = {
			phoneNumber: employeeDetails?.mobileNo || '',
			emailId: invitePortalEmailId,
			employeeId: employeeDetails?.employeeId,
			userFirstLastName: employeeDetails?.name,
		};
		const data = {
			email: invitePortalEmailId,
			emp_Id: employeeDetails?.id,
		};
		(!employeeDetails?.emailId || (invitePortalEmailId && invitePortalEmailId !== employeeDetails?.emailId)) &&
			dispatch(profileAction?.creators?.updateEmailStart(data));
		invitePortalEmailId !== '' && dispatch(profileAction?.creators?.inviteEmpToPortalStart(payload));
	};

	// payroll Setting Tab
	// checking claim
	const settingCleverTapProperties = {
		EmployeeID: employeeDetails?.employeeId,
		EmployeeName: employeeDetails?.name,
		AccountType: employeeDetails?.accountTypeDescription,
		JoiningDate: payrollDetails?.joiningDate,
		ContractType: payrollDetails?.contractType,
		ContractEndDate: payrollDetails?.endDate,
		Department: payrollDetails?.department,
		Designation: payrollDetails?.designation,
		BasicSalary: payrollDetails?.salary,
		...arrayToObject(addAllowance, 'aed', 'Allowance', true),
	};
	const allowancevalidation = addAllowance?.every((item) => item?.allowance);
	const aedvalidation = addAllowance?.every((item) => item?.aed && item?.aed != 0);
	const payrollClaims = useSelector((state) => state?.sharedStates?.currentUser?.payrollClaims) || [];
	const payrollEmployeeContracts = useSelector((state) => state?.payrollReducer?.payrollDetails?.employeeContracts) || [];
	let isPayrollSettingClaim = [];
	const payrollClaimData = payrollClaims?.map((item) => {
		if (managePayrollClaim?.includes(item?.value)) {
			isPayrollSettingClaim.push(item);
		}
	});
	const payrollSettingButtonDisable = useMemo(
		() => buttonDisable(payrollDetails, addAllowance, payrollEmployeeContracts, isEnabled),
		[payrollDetails, addAllowance, isEnabled]
	);

	// payroll run tab
	const payRollRun = useSelector((state) => state?.PayRollRunReducer?.payRollRunLists) || [];
	const saveValue = useSelector((state) => state?.PayRollRunReducer?.payRollRun) || [];
	const nPayRollRun = JSON.parse(JSON.stringify(payRollRun)) || [];
	const nAdditions = !additions?.every((item) => item.name && parseFloat(item.value) >= 0);
	const nDeductions = !deductions?.every((item) => item.name && parseFloat(item.value) >= 0);
	const nemployeeAllowan = employeeAllowance?.every((item) => item.value !== '');
	let isPayrollRunClaims = [];
	const payrollRunClaimData = payrollClaims?.map((item) => {
		if (managePayrollClaim?.includes(item?.value)) {
			isPayrollRunClaims.push(item);
		}
	});
	const payrollRunButtonDisable = useMemo(() => JSON.stringify(nPayRollRun) === JSON.stringify(saveValue), [nPayRollRun, saveValue]);

	// payroll document tab
	const payrollDocumentDisableButton = useMemo(
		() => JSON.stringify(removeNullFromObject(empDocState)) === JSON.stringify(removeNullFromObject(backupData)),
		[empDocState, backupData]
	);

	const shouldDisableButtonProfile = () => {
		let result = false;
		const profileNotChanged = forEnablingButton(employeeDetails, employeeDetailState);
		if (profileNotChanged === false) {
			if (
				typeof employeeDetailState?.emailId === 'string' &&
				employeeDetailState?.emailId !== '' &&
				!!isEmailValid(employeeDetailState?.emailId) === false
			) {
				result = true;
			} else if (typeof employeeDetailState?.iban === 'string' && employeeDetailState?.iban !== '' && employeeDetailState?.iban?.length > 34) {
				result = true;
			} else if (
				typeof employeeDetailState?.wpsPersonid === 'string' &&
				employeeDetailState?.wpsPersonid !== '' &&
				(employeeDetailState?.wpsPersonid?.length !== 14 || !isOnlySpecialCharacter(employeeDetailState?.wpsPersonid))
			) {
				result = true;
			} else if (
				typeof employeeDetailState?.wpsEstablishmentId === 'string' &&
				employeeDetailState?.wpsEstablishmentId !== '' &&
				(employeeDetailState?.wpsEstablishmentId?.length !== 13 || !isOnlySpecialCharacter(employeeDetailState?.wpsEstablishmentId))
			) {
				result = true;
			} else if (
				typeof employeeDetailState?.labourCardNo === 'string' &&
				employeeDetailState?.labourCardNo !== '' &&
				(employeeDetailState?.labourCardNo?.length < 5 || employeeDetailState?.labourCardNo?.length > 9)
			) {
				result = true;
			}
		} else if (
			typeof employeeDetailState?.dob === 'string' &&
			employeeDetailState?.dob !== '' &&
			employeeDetailState?.dob.length > 5 &&
			employeeDetailState?.dob.length < 10
		) {
			result = true;
		} else {
			result = true;
		}
		return result;
	};
	const shouldDisableButtonPayrollRun = () => {
		let result = true;
		const additionsTrue = saveValue.additions ? [...saveValue.additions] : [];
		const deductionsTrue = saveValue.deductions ? [...saveValue.deductions] : [];
		const leavingDateTrue = saveValue?.employeeContract?.leavingDate;
		const terminationTypeIdTrue = saveValue?.employeeContract?.terminationTypeId;
		const gratuityValueTrue = saveValue?.gratuity?.value;
		if (nPayRollRun?.employeeBasicSalary === null || nPayRollRun?.employeeBasicSalary?.value === 0 || !!!salaryTypeList) {
			return result;
		} else {
			if (gratuityValueTrue && !gratuity) {
				result = false;
			}
			if (payrollRunButtonDisable !== true) {
				result = false;
			} else if (additionsTrue && additions && additionsTrue.length !== additions.length) {
				result = false;
			} else if (deductionsTrue && deductions && deductionsTrue.length !== deductions.length) {
				result = false;
			} else if (typeof leavingDate === 'string' && leavingDate !== '' && `${leavingDateTrue}` !== `${leavingDate}`) {
				result = false;
			} else if (typeof terminationId === 'number' && terminationTypeIdTrue !== terminationId) {
				result = false;
			} else if (typeof gratutiycal === 'string' && gratutiycal !== '' && `${gratuityValueTrue}` !== `${gratutiycal}`) {
				result = false;
			}
			if (additionsTrue.length > 0 && additionsTrue.length === additions.length) {
				let _result = true;
				for (const additionTrue of additionsTrue) {
					if (_result === false) {
						break;
					}
					for (const addition of additions) {
						if (additionTrue.id === addition.id) {
							if (addition.value !== '' && `${additionTrue.value}` !== `${addition.value}`) {
								_result = false;
								break;
							}
						}
					}
				}
				if (_result === false) {
					result = false;
				}
			}
			if (deductionsTrue.length > 0 && deductionsTrue.length === deductions.length) {
				let _result = true;
				for (const deductionTrue of deductionsTrue) {
					if (_result === false) {
						break;
					}
					for (const deduction of deductions) {
						if (deductionTrue.id === deduction.id) {
							if (deduction.value !== '' && `${deductionTrue.value}` !== `${deduction.value}`) {
								_result = false;
								break;
							}
						}
					}
				}
				if (_result === false) {
					result = false;
				}
			}
		}
		return result;
	};

	//profile global button disable
	// true - disable button
	// false - enable button
	const handleDisableProfileButton = (tab) => {
		switch (tab) {
			case '1':
				const result = shouldDisableButtonProfile();
				return result;
			case '2':
				return shouldDisableButtonPayrollRun() || isPayrollRunClaims?.length === 0;
			case '3':
				return payrollSettingButtonDisable || isPayrollSettingClaim?.length === 0;
			case '4':
				return isLeaveEdited;
			case '5':
				return hasErrors || (!buttonFlag && payrollDocumentDisableButton);
			case '6':
				return true;
			default:
				return true;
		}
	};
	// profile global save button
	const handleSaveProfileButton = (tab) => {
		switch (tab) {
			case '1':
				handleProfileSave();
				setNotification(false);
				break;
			case '2':
				handleProfilRuneSave();
				setNotification(false);
				break;
			case '3':
				setIsEnabled({ ...isEnabled, disAedAble: true });
				allowancevalidation && aedvalidation && handleProfileSettingSave();
				ClevertapReact.event('Save_PayrollSettings', { ...cleverTapProperties });
				setNotification(false);
				break;
			case '4':
				handleProfileSave();
				setNotification(false);
				break;
			case '5':
				handleProfileDocumentSave();
				setNotification(false);
				break;
			default:
				break;
		}
	};

	const getGratuityObj = (gratutiycal) => {
		if (payRollRun?.gratuity?.id) {
			return {
				id: payRollRun?.gratuity?.id,
				value: gratutiycal,
			};
		}
		return {
			value: gratutiycal,
		};
	};

	const handleProfilRuneSave = () => {
		const cleverTapProperties = {
			BasicSalary: empolyeeSalary?.value || 0,
			OvertimeOnWeekdays: overTimes?.[0]?.hours || 0,
			OvertimeOnWeekends: overTimes?.[1]?.hours || 0,
			OvertimeOnPH: overTimes?.[2]?.hours || 0,
			Gratuity: gratutiycal || 0,
			TypeOfTermination: termiation || payRollRun?.employeeContract?.terminationType,
			...arrayToObject(additions, 'value', 'Addition', true),
			...arrayToObject(deductions, 'value', 'Deduction', true),
			...arrayToObject(employeeAllowance, 'value', 'Allowance', true),
		};
		ClevertapReact.event('Save_PayrollRun', { cleverTapProperties });

		const result = {
			...savePayrollValue,
			additionList: [...additions],
			deductionList: [...deductions],
			allowanceList: [...employeeAllowance],
			overtimePayList: [...overTimes],
			leavingDate: leavingDate,
			terminationTypeId: terminationId || payRollRun?.employeeContract?.terminationTypeId,
			gratuity: getGratuityObj(gratutiycal),
		};

		// setSaveValue(saveValue);
		dispatch(payRollRunAction.creators.getSavePayrollRunStart(employeeDetails, result, month, year, gratuity));
		// handleSaveSaga(true);
		setNotification(false);
		setNavigateTabCheck({
			...navigateTabCheck,
			isTabClicked: false,
			tabKey: '2',
		});
	};
	const handleProfileSettingSave = () => {
		setNavigateTabCheck({
			...navigateTabCheck,
			isTabClicked: false,
			tabKey: '3',
		});
		const startDate = payrollDetails?.joiningDate.split('/').reverse().join('-');
		const endDate = payrollDetails?.endDate.split('/').reverse().join('-') || '';
		const response = addAllowance?.map((data) => {
			return {
				id: data?.id || '',
				allowanceTypeId: data?.allowanceId || '',
				allowanceTypeName: data?.allowance,
				value: parseFloat(amountWithNoCommas(data?.aed)),
			};
		});
		const payload = {
			setting: {
				id: payrollDetails?.id || '',
				employeeId: employeeDetails?.employeeId,
				startDate: startDate,
				endDate: payrollDetails?.contractType === 'Limited' ? endDate : '',
				contractTypeId: payrollDetails?.contractTypeId,
				contractType: payrollDetails?.contractType,
				departmentId: payrollDetails?.departmentId,
				designation: payrollDetails?.designation,
				basicSalary: parseFloat(amountWithNoCommas(payrollDetails?.salary)),
				allowanceList: response || [],
			},
		};
		dispatch(payrollSettingAction.creators.getPayrollSubmitStart(payload));
	};
	const cleverTapProperties = {
		WorkPermitNumber: empDocState?.workPermitNumber ? 'yes' : 'no',
		PersonalNumber: empDocState?.labourCardNumber ? 'yes' : 'no',
		LabourCardExpiryDate: empDocState?.labourCardExpiryDate ? 'yes' : 'no',
		LabourCardImage: imageData?.labourCardScan ? 'yes' : 'no',
		EmiratesIdNumber: empDocState?.emiratesIdNumber ? 'yes' : 'no',
		EmiratesIdIssueDate: empDocState?.emiratesIssueDate ? 'yes' : 'no',
		EmiratesIdExpiryDate: empDocState?.emiratesExpiryDate ? 'yes' : 'no',
		EmiratesIdFrontPage: imageData?.emiratesFrontScan ? 'yes' : 'no',
		EmiratesIdBackPage: imageData?.emiratesBackScan ? 'yes' : 'no',
		VisaNumber: empDocState?.visaNumber ? 'yes' : 'no',
		VisaIssueDate: empDocState?.visaIssueDate ? 'yes' : 'no',
		VisaExpiryDate: empDocState?.visaExpiryDate ? 'yes' : 'no',
		VisaImage: imageData?.visaScan ? 'yes' : 'no',
		PassportNumber: empDocState?.passportNumber ? 'yes' : 'no',
		PassportImage: imageData?.passportScan ? 'yes' : 'no',
	};
	const isDuplicateCheck = useSelector((state) => state?.individualData?.duplicateCheckData);
	const handleProfileDocumentSave = () => {
		setNavigateTabCheck({
			...navigateTabCheck,
			isTabClicked: false,
			tabKey: '5',
		});
		if (
			!empDocumentForm.getFieldsError().some(({ errors }) => errors.length) &&
			!expiryDates?.labour &&
			!expiryDates?.emirates &&
			!expiryDates?.visa &&
			!expiryDates?.passport &&
			!isDuplicateCheck
		) {
			dispatch(DocumentAction.creators.updateProfileData(employeeDetails?.id, empDocState, imageData));
			ClevertapReact.event('Docs_profile_submit', { cleverTapProperties });
		}
	};

	return (
		<ViewEmployeePresentational
			{...{
				handleProfileSave,
				employeeDetailState,
				setEmployeeDetailState,
				employeeDetails,
				setEmployeeDetails,
				buttonDisable: empProfileButtonDisable,
				profileSaveButtonEnable,
				isEmailValided,
				isShowLoader,
				navigateTabCheck,
				setNavigateTabCheck,
				bankOptions,
				wpsDetails,
				data,
				isDataLoading,
				employeeDocument,
				employeeHistory,
				sampleData,
				setSampleData,
				leaveTypelist,
				setLeaveTypelist,
				isLeaveEdited,
				employeeProfileCard,
				employeeRoles,
				payrollDetails,
				setPayrollDetails,
				addAllowance,
				setAddAllowance,
				isEnabled,
				setIsEnabled,
				handleDisableProfileButton,
				handleSaveProfileButton,
				buttonFlag,
				setButtonFlag,
				hasErrors,
				setHasErrors,
				empDocState,
				setEmpDocState,
				backupData,
				setBackupData,
				additions,
				setAdditions,
				deductions,
				setDeductions,
				gratuity,
				setGratuity,
				runEmployeeAllowanceList,
				setRunEmployeeAllowanceList,
				employeeProfilePopup,
				setEmployeeProfilePopup,
				handleC3PayToBankAccount,
				loading,
				modalOpen,
				cardloading,
				replacementCardMessage,
				confirmationModelOpen,
				popupVisible,
				popupContent,
				setPopupVisible,
				setPopupContent,
				afterConfirmationOpenModal,
				confirmationsRequest,
				afterOpenModal,
				getDeleterole,
				setModalOpen,
				closePopup,
				employeeCardAddress,
				dispatch,
				setInvitePortalEmailId,
				invitePortalEmailId,
				handleInviteEmployeeToPortal,
				notification,
				setNotification,
				expiryDates,
				setExpiryDates,
				imageData,
				setImageData,
				empDocumentForm,
				employeeAllowance,
				setEmpolyeeAllowance,
				empolyeeSalary,
				setEmpolyeeSalary,
				termiation,
				setTerminationType,
				overTimes,
				setOverTimes,
				month,
				setmonth,
				year,
				setyear,
				savePayrollValue,
				setSaveValue,
				gratutiycal,
				setGratuityCal,
				getEmployeeById,
				leavingDate,
				setLeavingDate,
				terminationId,
				setTerminationId,
			}}
		/>
	);
};

export default ViewEmployeeFunctional;
