import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Col, Tooltip } from 'antd';
import { ClipLoader } from 'react-spinners';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { APP_VARIABLES, DEFAULT_TEXT } from '@constants/app-constants';
import { checkHrSubscription } from '@helpers';
import action from '@sharedComponent/app-action-reducer/action';
import SelectorsApp from '@sharedComponent/app-action-reducer/selectors';
import ImageComponent from '@sharedComponent/image-component';
import Action from '@sharedComponent/app-action-reducer/action';
import Chips from '@pages/DesignSystem/Chips';
import EmployeeService from 'src/services/employee-service';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import { withReducer } from 'src/store/reducerLoader';
import PopupComponent from './popup-component';
import Selectors from '../selector';
import Actions from '../action';
import reducer from '../reducer';
import sagas from '../saga/index';
import ManageEmployeePresentational from './manage-employee-presentational';
import useClaimsBasedContents from '../../hooks/useClaimsBasedContents/useClaimsBasedContents';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import { MANAGE_EMPLOYEES_CONSTANTS } from '../constants';
import ClevertapReact from 'src/utils/clever-tap';
import DOMPurify from 'dompurify';

const ManageEmployeeFunctional = (props) => {
	const { activeAccounts, creatingAccounts, deletedAccounts, needAttention } = MANAGE_EMPLOYEES_CONSTANTS.tabsContents;
	const [dataCountToShow, setDataCountToShow] = useState(10);
	const [employeeTableData, setEmployeeTableData] = useState([]);
	const [employetableSelect, setEmployeTableSelect] = useState([]);
	const [selectedEmployees, setSelectedEmployees] = useState([]);
	const [selectedEmpCode, setSelectedEmpCode] = useState([]);
	const [employetableNotSelect, setEmployeTableNotSelect] = useState([]);
	const [selectedEmployee, setSelectedEmployee] = useState([]);
	const [selectedEmployeeLists, setSelectedEmployeeLists] = useState([]);
	const [activeTabs, setactiveTab] = useState(activeAccounts.key);
	const activeTabRef = useRef(null);
	const { state } = useLocation();
	const [visible, setVisible] = useState(false);
	const [popupVisible, setpopupVisible] = useState(false);
	const [subscription, setSubscription] = useState(false);
	const [confirmationModelOpen, setConfirmationModelOpen] = useState(false);
	const [popupContent, setPopupContent] = useState({
		title: '',
		content: '',
		onOkayButton: 'Ok'
	});
	const [emp_Id, setEmp_Id] = useState();
	const [mailState, setMailState] = useState({
		mobileNumber: '',
		employeeCode: '',
		emailId: '',
		isEmail: '',
		employeeName: '',
		employeeId: '',
		portalInviteStatus: '',
	});
	const [cardloading, setcardloading] = useState(false);
	const [kycStatus, setKycStatus] = useState(false);
	let [selectedTab, setSelectedTab] = useState(activeAccounts.key);
	let [totalFilterCount, setTotalFilterCount] = useState(0);
	let [enableFooter, setEnableFooter] = useState(false);
	let [enableCutOff, setEnableCutOff] = useState(false);
	const [isData, setIsData] = useState(true);
	const [activeAccountPagination, setactiveAccountPagination] = useState({
		pagination: {
			current: 1,
			pageSize: dataCountToShow,
		},
	});
	const [creatingAccountPagination, setcreatingAccountPagination] = useState({
		pagination: {
			current: 1,
			pageSize: dataCountToShow,
		},
	});
	const [needAttentionPagination, setNeedAttentionPagination] = useState({
		pagination: {
			current: 1,
			pageSize: dataCountToShow,
		},
	});
	const [deletedAccountPagination, setdeletedAccountPagination] = useState({
		pagination: {
			current: 1,
			pageSize: dataCountToShow,
		},
	});

	const { shouldRender: renderBasedOnClaims } = useClaimsBasedContents();
	const [accountType, setAccountType] = useState({
		c3appUser: '',
		accountType: '',
	});

	let userLocation = useSelector((state) => state?.sharedStates?.corporateIds);
	let address = Object?.assign(
		{},
		...(userLocation || [])?.filter((corporate) => corporate?.corporateId === localStorage.getItem(APP_VARIABLES.CORPORATE_ID))
	);
	const afterConfirmationOpenModal = () => {
		setConfirmationModelOpen(false);
	};
	const confirmationsRequest = async () => {
		setcardloading(true);
		try {
			let response = await employeeApi.replacementCard({ confirmation: 'yes' }, mailState?.employeeId);
			setcardloading(false);
			//if responce has some message show popup
			if (response && response?.status === 200) {
				// show normal popup
				setConfirmationModelOpen(false);
				history.push({ pathname: '/employees/replace_card_next_step', state: { ...mailState } });
			}
		} catch (err) {
			setcardloading(false);
			setpopupVisible(true);
			setPopupContent({
				title: 'Something went wrong',
				content: null,
			});
		}
	};
	let [employeeCount, setEmployeeCount] = useState({
		activeEmployeeCount: 0,
		creatingAccountNoCount: 0,
		deleteAccountCount: 0,
		needAttentionCount: 0,
	});

	let [isDataLoading, setIsDataLoading] = useState(false);
	let [filterData, setFilterData] = useState({
		accountTypes: [],
		bankNames: [],
		cardStatuses: [],
		emiratesIdStatuses: [],
		establishments: [],
		payrollChannels: [],
		needAttentionStatuses: [],
	});

	let [selectedFilterCount, setSelectedFilterCount] = useState({
		accountTypes: 0,
		bankNames: 0,
		cardStatuses: 0,
		emiratesIdStatuses: 0,
		establishments: 0,
		payrollChannels: 0,
		needAttentionStatuses: 0,
	});
	const [searchValue, setSearchValue] = useState('');
	const [activeFilterValues, setActiveFilterValues] = useState(null);

	let history = useHistory();
	let employeeApi = new EmployeeService();
	const buttonRef = useRef(null);
	const getEmployeeStatusCountApiCalled = useRef(false);
	let queryParamFieldMapping = {
		accountTypes: 'accountType',
		bankNames: 'bankName',
		cardStatuses: 'cardStatus',
		emiratesIdStatuses: 'emiratesIdStatus',
		establishments: 'establishmentId',
		payrollChannels: 'payrollChannel',
		needAttentionStatuses: 'needAttentionFilter',
	};
	let labelFieldMapping = {
		accountTypes: 'accountTypeName',
		bankNames: 'bankName',
		cardStatuses: 'cardStatus',
		emiratesIdStatuses: 'emiratesIdStatus',
		establishments: 'establishmentId',
		payrollChannels: 'payrollChannelName',
		needAttentionStatuses: 'key',
	};
	let currentUser = useSelector((state) => state?.sharedStates?.currentUser);

	useEffect(() => {
		activeTabRef.current = activeTabs;
	}, [activeTabs]);

	async function getCounts() {
		try {
			if (getEmployeeStatusCountApiCalled.current === false) {
				getEmployeeStatusCountApiCalled.current = true;
				let { data } = await employeeApi.getEmployeeStatusCount();
				setEmployeeCount({
					...employeeCount,
					...data,
				});
			}
		} catch (err) {}
	}

	useEffect(() => {
		setIsData(employeeTableData?.length <= 0);
	}, [employeeTableData]);

	useEffect(() => {
		let otherParams = {};
		if (state) {
			otherParams = {
				c3appUser: state?.type === 'C3Pay App Users' ? DEFAULT_TEXT?.YES : '',
				accountType: state?.type !== 'Total Employees' ? state?.type : '',
			};
			setAccountType({
				...accountType,
				c3appUser: state?.type === 'C3Pay App Users' ? DEFAULT_TEXT?.YES : '',
				accountType: state?.type !== 'Total Employees' ? state?.type : '',
			});
		}
		if (currentUser && currentUser?.roles && currentUser?.payrollClaims) {
			let role = currentUser.payrollClaims?.map((data) => data?.value);
			setEnableFooter(role?.includes('Manage.Employees'));
			setEnableCutOff(role?.includes('Manage.Employees'));
		}
		if (props?.history?.location?.state && props?.history?.location?.state?.filterData) {
			handleFilterChange(props?.history?.location?.state?.filterData);
			setFilterData({ ...props?.history?.location?.state?.filterData });
		} else {
			getEmployeeDetails('', otherParams); // initial employees data call
		}
	}, []);

	const initialRender = useRef(true); // for using to avoid repetitive calls on initial render
	useEffect(() => {
		if (initialRender.current) {
			initialRender.current = false;
			return;
		}
		handleTablePageEntiresChange();
	}, [dataCountToShow]); // pagination entries change on table header

	// filter loading lookup data
	async function fetchFilterLookupData(status = activeAccounts.status) {
		const searchParams = {
			status: status,
		};
		try {
			const response = await employeeApi.getFilterLookupData(searchParams);
			if (response && response?.data) {
				Object.keys(response?.data).map((val) => {
					return response?.data[val]?.map((val1) => (val1.isSelected = false));
				});

				delete response?.data?.cardStatuses;
				delete response?.data?.emiratesIdStatuses;
				setFilterData({ ...response.data });
			}
		} catch (err) {}
	}

	//handle individual employee select in table
	const handleCheckBox = (employeeCode) => {
		let SelectedEmp = [];
		const newSelectEmp = selectedEmployeeLists?.map((data) => data?.employeeCode);
		if (newSelectEmp.indexOf(employeeCode) >= 0) {
			let updatedSelected = selectedEmployee?.filter((code) => code !== employeeCode);
			let updatedSelectedLists = selectedEmployeeLists?.filter((code) => code?.employeeCode !== employeeCode);
			setSelectedEmployee(updatedSelected);
			setSelectedEmployeeLists(updatedSelectedLists);
			setMobileViewData(employeeTableData, updatedSelected);
		} else {
			let updatedSelected = [...selectedEmployee];
			let updatedSelectedLists = [...selectedEmployeeLists];
			updatedSelected.push(employeeCode);
			let employee = employeeTableData
				?.map((data) => {
					return {
						...data,
						isEmpSelected: true,
						pageNumber: activeAccountPagination?.pagination?.current,
					};
				})
				?.filter((code) => code?.employeeCode === employeeCode);
			updatedSelectedLists.push(employee[0]);
			setMobileViewData(employeeTableData, updatedSelected);
			setSelectedEmployee([...updatedSelected]);
			setSelectedEmployeeLists([...updatedSelectedLists]);
		}
		SelectedEmp = employeeTableData.filter((employee) => {
			if (employeeCode === employee.employeeCode) {
				return employee;
			}
		});
		if (!selectedEmpCode.indexOf(SelectedEmp[0].employeeCode) >= 0) {
			let downloadSelected = selectedEmployees.concat(SelectedEmp[0]);
			let downloadSelectedCode = selectedEmpCode.concat(SelectedEmp[0]?.employeeCode);
			setSelectedEmployees(downloadSelected);
			setSelectedEmpCode(downloadSelectedCode);
		}
	};

	// view-employee-details
	const handlepassdata = (tab, val) => {
		let data = val;
		history.push({ pathname: '/employees/viewemployee', state: { selectedTab: tab, tableData: data, mainProfile: false } });
	};

	const isSubcribed = checkHrSubscription(JSON.parse(localStorage.getItem(APP_VARIABLES.PROD_SUBSCRIPTIONS)));
	const switchCard = (type, data) => {
		switch (type) {
			case null:
				return (
					isSubcribed && (
						<Col className="emp_card" onClick={() => handleInvitePopup(type, data)}>
							<p className="invite_to_portal">Invite To Portal</p>
						</Col>
					)
				);
			case 1:
				return (
					isSubcribed && (
						<Col className="emp_card_reissue" onClick={() => handleInvitePopup(type, data)}>
							{data?.isResentCardLoading ? (
								<ClipLoader size={13} color={'#484b52'} loading />
							) : (
								<p className="resend_invitation">Resend Invitation</p>
							)}
						</Col>
					)
				);
			case 3:
				return (
					<Col className="emp_card_replace">
						<EdenredButtons
							btnType="secondary"
							disabled={data?.isReplaceLoad}
							onClick={() => handleInvitePopup(type, data)}
						>
							{data?.isReplaceLoad ?
								<ClipLoader size={13} color={'#484b52'} loading />
								: 'Replace Card'
							}
						</EdenredButtons>
					</Col>
				);
			default:
				break;
		}
	};
	const fetchEmployeesQueryParams = {
		status: 'ACTIVE',
		includeEmployeeCounts: true,
		pageSize: dataCountToShow,
		pageNumber: activeAccountPagination?.pagination?.current,
		payrollBlockCheck: false,
	};
	useEffect(() => {
		if (props.inviteCardStatus === 'Success') {
			let queryParams = fetchEmployeesQueryParams;
			fetchEmployees(queryParams);
			props.inviteCardStatusSaga('');
		}
	}, [props.inviteCardStatus]);

	const handleInvitePopup = (value, val1) => {
		setMailState({
			...mailState,
			mobileNumber: val1?.mobileNumber || '',
			employeeCode: val1?.employeeCode || '',
			emailId: val1?.emailId || '',
			isEmail: val1?.emailId || '',
			employeeName: val1?.employeeName || '',
			employeeId: val1?.employeeId || '',
			portalInviteStatus: val1?.portalInviteStatus,
		});
		if (isSubcribed) {
			if (val1?.portalInviteStatus === null && val1?.accountType !== 'C3Pay Cardholders') {
				props.invitePortalPopup(true);
			} else if ((val1?.portalInviteStatus === 1 || val1?.portalInviteStatus === 2) && val1?.accountType !== 'C3Pay Cardholders') {
				setEmployeeTableData(replaceCardLoading('isResentCardLoading', val1, true));
				props.emailDataSaga(val1);
			} else {
				setEmployeeTableData(replaceCardLoading('isReplaceLoad', val1, true));
				replaceCardRequesting(val1);
			}
		} else if (value === 3) {
			setEmployeeTableData(replaceCardLoading('isReplaceLoad', val1, true));
			replaceCardRequesting(val1);
		} else {
			setSubscription(true);
		}
	};

	const replaceCardLoading = (value, val1, loading) => {
		return employeeTableData?.map((data) => {
			if (data?.employeeId === val1?.employeeId) {
				return {
					...data,
					[value]: loading,
				};
			}
			return data;
		});
	};
	const replaceCardRequesting = async (val1) => {
		try {
			let response = await employeeApi.getEmployeeById(val1?.employeeId);
			if (response && response.data) {
				setEmployeeTableData(replaceCardLoading('isReplaceLoad', val1, false));
				const employee = response?.data?.employee ?? {};
				if (!employee.requestReplacementCardButtonEnabledState) {
					let { title = '', content = '' } = employee.requestReplacementCardMessage ?? {};
					if(!title && !content) {
						title = `Unable to Process Card Replacement. \n Please Try Again Later.`
						content = `If the issue persists, please contact us at <a href="mailto:support.ae@edenred.com">support.ae@edenred.com</a> for assistance.`
					}
					setpopupVisible(true);
					setPopupContent({
						title: title,
						content: <div className='card_replacement_message' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />,
						onOkayButton: 'Understood'
					});
				} else {
					setpopupVisible(false);
					setConfirmationModelOpen(true);
					employee.kycStatus === true ? setKycStatus(true) : setKycStatus(false);
				}
			}
		} catch (err) {
			setEmployeeTableData(replaceCardLoading('isReplaceLoad', val1, false));
			setpopupVisible(false);
		}
	};

	const commonColumns = [
		{
			sorter: (a, b) => a.employeeName.localeCompare(b.employeeName),
			key: 'employeeName',
			title: 'Name',
			dataIndex: 'employeeName',
			width: '20%',
			hidden: false,
			activeTab: true,
			sort: true,
			render: (val, rowData) => {
				return (
					<Col
						className="name empName"
						style={{
							display: 'grid',
							gap: '2px',
						}}
						// Passing data as 1 by default for the employee name selection
						onClick={(e) => handlepassdata('1', rowData)}>
						<span
							style={{
								textDecoration: 'underline',
								color: '#2B769C',
								cursor: 'pointer',
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}>
							{val}
						</span>
						<span
							style={{
								color: '#181919',
								fontSize: ' 10px',
								fontStyle: 'normal',
								fontWeight: 300,
								lineHeight: '12px' /* 120% */,
							}}>
							{rowData?.employeeCode || ''}
						</span>
					</Col>
				);
			},
		},
	];

	// Need attention tab status colors
	const cardStatusChipColor = (statusKey = '') => {
		switch (statusKey) {
			case 1:
				return '#FFBECB';
			case 2:
				return '#FEF5E8';
			case 3:
				return '#EEDCBA';
			case 4:
				return '#EFF1F5';
			default:
				return null;
		}
	};

	const needAttentionTabColumns = [
		{
			sorter: (a, b) => a.needAttentionCardStatus - b.needAttentionCardStatus,
			key: 'needAttentionCardStatus',
			title: 'Card Status',
			dataIndex: 'needAttentionCardStatus',
			width: '14%',
			activeTab: true,
			sort: true,
			render: (status) => {
				const { key, value } = status || {};
				return (
					<Col className="valid_detail">
						<Chips color={cardStatusChipColor(key)}>{value || ''}</Chips>
					</Col>
				);
			},
		},
		{
			sorter: (a, b) => a.reason - b.reason,
			key: 'reason',
			title: 'Reason ',
			dataIndex: 'reason',
			width: '14%',
			activeTab: true,
			sort: true,
			render: (val) => {
				return <Col className="valid_detail">{val || null}</Col>;
			},
		},
		{
			sorter: (a, b) => a.action - b.action,
			key: 'action',
			title: 'Action ',
			dataIndex: 'action',
			width: '14%',
			activeTab: true,
			sort: true,
			render: (val) => {
				return <Col className="valid_detail">{val || null}</Col>;
			},
		},
	];

	const otherTabColumns = [
		{
			sorter: (a, b) => a.designation - b.designation,
			key: 'designation',
			title: 'Designation',
			dataIndex: 'designation',
			width: '14%',
			hidden: !isSubcribed,
			activeTab: true,
			sort: true,
			render: (val, val1) => {
				return <Col className="valid_detail">{val || null}</Col>;
			},
		},
		{
			sorter: (a, b) => a.accountType - b.accountType,
			key: 'accountType',
			title: 'Status',
			dataIndex: 'accountType',
			width: '14%',
			hidden: !renderBasedOnClaims.accountTypeColumn,
			activeTab: true,
			sort: true,
			render: (val, val1) => {
				if (val === 'C3Pay Cardholders') {
					return (
						// C3Pay App User
						<Col className="valid_detail">
							<Chips color="#CCECE6">C3Pay Cardholders</Chips>
						</Col>
					);
				}
				if (val === 'Bank Accounts') {
					return (
						<Col className="valid_detail">
							<Chips color="#FFBECB">Bank Accounts</Chips>
						</Col>
					);
				} else {
					return (
						<Col className="valid_detail">
							<Chips color="#F7F8FA">{val || null}</Chips>
						</Col>
					);
				}
			},
		},
		{
			key: '',
			title: '',
			dataIndex: '',
			width: '10%',
			hidden: !renderBasedOnClaims.deleteCardColumn,
			activeTab: selectedTab === activeAccounts.key,
			sort: false,
			render: (val, val1) => {
				const Component = false ? Tooltip : Fragment;
				const datas =
					(val1?.portalInviteStatus === null || val1?.portalInviteStatus === 1 || val1?.portalInviteStatus === 2) &&
					val1.accountType !== 'C3Pay Cardholders'
						? val1?.portalInviteStatus !== 2
							? val1?.portalInviteStatus
							: 1
						: 3;
				return (
					<Col className="valid_detail_1	">
						<Component placement="top" title="The replacement card request is pending your approval" overlayClassName="toolTipOverlay">
							{switchCard(datas, val1)}
						</Component>
						<Col className="emp_delete" onClick={() => showModal(val, val1)} style={{ cursor: 'pointer' }}>
							<ImageComponent className="emp_trash_img" src={not_focus_icons?.Trash} />
						</Col>
					</Col>
				);
			},
		},
	];

	const tableColumnsData = [...commonColumns, ...(selectedTab === needAttention.key ? needAttentionTabColumns : otherTabColumns)];
	const columns = tableColumnsData.filter((item) => !item?.hidden).filter((data) => data?.activeTab);

	async function getEmployeeDetails(status, otherParams = {}) {
		if (!status) {
			status = getStatusForTab(selectedTab);
		}
		setIsDataLoading(true);
		let queryParams = {
			status: status,
			includeEmployeeCounts: true,
			pageSize: dataCountToShow,
			pageNumber: 1,
			payrollBlockCheck: false,
			...otherParams,
		};
		await fetchEmployees(queryParams);
		setIsDataLoading(false);
	}

	const [employeeListForDownload, setemployeeListForDownload] = useState({
		data: [],
		loading: false,
	});
	async function getEmployeeDetailsForDownload(status, otherParams = {}) {
		if (!status) {
			status = getStatusForTab(selectedTab);
		}
		let queryParams = {
			status: status,
			includeEmployeeCounts: true,
			pageSize: '',
			pageNumber: 1,
			...otherParams,
		};
		await fetchEmployeesForDownload(queryParams);
	}
	const [totalCount, settotalCount] = useState(0);

	async function fetchEmployees(params = {}) {
		try {
			// we are creating a new params object by spreading this function params
			const queryParams = {
				...params,
			};
			// if we have active status we insert this key in the params
			if (queryParams?.status === activeAccounts.status) {
				queryParams.checkReplacement = true;
			}
			getCounts(); // getting status counts for all tabs
			let response = await employeeApi.getEmployees(queryParams);
			if (response && response?.data) {
				const empDta = response?.data?.employees?.map((empData) => {
					return {
						...empData,
						isEmpSelected: false,
						isReplaceLoad: false,
						isResentCardLoading: false,
						pageNumber: 0,
					};
				});
				setEmployeeTableData([...empDta]);
				setMobileViewData(response?.data?.employees);
				settotalCount(response?.data?.totalCount);
			}
		} catch (err) {
			setEmployeeTableData([]);
			setMobileViewData(null);
		}
	}

	async function fetchEmployeesForDownload(queryParams) {
		setemployeeListForDownload({
			...employeeListForDownload,
			loading: true,
		});
		try {
			let response = await employeeApi.activeEmployeesDownload(queryParams);

			if (response && response.data) {
				setemployeeListForDownload({
					...employeeListForDownload,
					loading: false,
				});
				const downloadUrl = window.URL.createObjectURL(new Blob([response.data], {}));
				const link = document.createElement('a');
				link.href = downloadUrl;
				link.setAttribute('download', `EmployeeList.xlsx`);
				document.body.appendChild(link);
				link.click();
				link.remove();
			}
		} catch (err) {
			setemployeeListForDownload({
				data: [],
				loading: false,
			});
		}
	}

	function setMobileViewData(employees, selectedEmployeeInfo = selectedEmployee) {
		if (!employees) {
			setEmployeTableSelect([]);
			setEmployeTableNotSelect([]);
			return;
		}
		let { selectedData, notSelectedData } = employees?.reduce(
			(acc, employee) => {
				if (selectedEmployeeInfo.indexOf(employee?.employeeCode) >= 0) {
					acc.selectedData.push(employee);
				} else {
					acc.notSelectedData.push(employee);
				}
				return acc;
			},
			{
				selectedData: [],
				notSelectedData: [],
			}
		);
		setEmployeTableSelect(selectedData);
		setEmployeTableNotSelect(notSelectedData);
	}

	function handleTabChange(activeTab, queryParams) {
		setSelectedTab(activeTab);
		const {
			pagination: { current: pageNumber },
		} = getPageNumber(activeTab); // pagenumber of the current tab

		let otherParams = { ...queryParams, pageNumber, ...accountType };
		if (activeTab === needAttention.key) {
			// clever tap event
			ClevertapReact.event(needAttention.cleverTapEvents.tab);
			if (!filterData.needAttentionStatuses.length) {
				// filter data load for need attention filter
				fetchFilterLookupData(needAttention.status);
			}

			let updatedSelectedFilterCount = { ...selectedFilterCount };
			Object.keys(filterData).forEach((key) => {
				let queryKey = queryParamFieldMapping[key];
				let selected = (Array.isArray(filterData[key]) && filterData[key].filter((data) => data.isSelected)) || [];
				updatedSelectedFilterCount[key] = selected?.length;
				if (selected?.length > 0) {
					otherParams[queryKey] = selected
						.reduce((acc, data) => {
							acc.push(data[labelFieldMapping[key]]);
							return acc;
						}, [])
						.join(',');
				}
			});
		}
		getEmployeeDetails(getStatusForTab(activeTab), otherParams);
	}

	function getStatusForTab(activeTab) {
		setactiveTab(activeTab);
		let status = '';
		switch (activeTab) {
			case activeAccounts.key:
				status = 'ACTIVE';
				break;
			case creatingAccounts.key:
				status = 'CREATINGACCOUNTNO';
				break;
			case needAttention.key:
				status = 'NEEDATTENTION';
				break;
			case deletedAccounts.key:
				status = 'DELETEACCOUNT';
				break;
			default:
				status = 'ACTIVE';
				break;
		}
		return status;
	}

	function getPageNumber(activeTab) {
		switch (activeTab) {
			case activeAccounts.key:
				return activeAccountPagination;
			case creatingAccounts.key:
				return creatingAccountPagination;
			case needAttention.key:
				return needAttentionPagination;
			case deletedAccounts.key:
				return deletedAccountPagination;
			default:
				return activeAccountPagination;
		}
	}

	// Table changing function
	const changeTablePage = async (tabKey = '', pageNumber = 1, type = { clearAllFilter: false }) => {
		const params = {
			pageNumber: pageNumber || 1,
			...accountType,
		};
		if (searchValue) {
			params.search = searchValue;
		}
		if (tabKey === needAttention.key && !type.clearAllFilter && activeFilterValues) {
			params[queryParamFieldMapping.needAttentionStatuses] = activeFilterValues;
		}
		getEmployeeDetails(getStatusForTab(tabKey), params);
	};

	// only using for updating the current pagenumber on pagination
	const changePageNumber = ({ current = 1 }) => {
		switch (selectedTab) {
			case activeAccounts.key:
				setactiveAccountPagination({
					...activeAccountPagination,
					pagination: {
						...activeAccountPagination.pagination,
						current,
					},
				});
				break;
			case creatingAccounts.key:
				setcreatingAccountPagination({
					...creatingAccountPagination,
					pagination: {
						...creatingAccountPagination.pagination,
						current,
					},
				});
				break;
			case needAttention.key:
				setNeedAttentionPagination({
					...needAttentionPagination,
					pagination: {
						...needAttentionPagination.pagination,
						current,
					},
				});
				break;
			case deletedAccounts.key:
				setdeletedAccountPagination({
					...deletedAccountPagination,
					pagination: {
						...deletedAccountPagination.pagination,
						current,
					},
				});
				break;
			default:
				break;
		}
	};
	const handlePaginationChange = ({ current }, type) => {
		switch (type) {
			case 'activeAccountPagination':
				setactiveAccountPagination({
					...activeAccountPagination,
					pagination: {
						...activeAccountPagination.pagination,
						current,
					},
				});
				changeTablePage(activeAccounts.key, current);
				break;
			case 'creatingAccountPagination':
				setcreatingAccountPagination({
					...creatingAccountPagination,
					pagination: {
						...creatingAccountPagination.pagination,
						current,
					},
				});
				changeTablePage(creatingAccounts.key, current);
				break;
			case 'needAttentionPagination':
				setNeedAttentionPagination({
					...needAttentionPagination,
					pagination: {
						...needAttentionPagination.pagination,
						current,
					},
				});
				changeTablePage(needAttention.key, current);
				break;
			case 'deletedAccountPagination':
				setdeletedAccountPagination({
					...deletedAccountPagination,
					pagination: {
						...deletedAccountPagination.pagination,
						current,
					},
				});
				changeTablePage(deletedAccounts.key, current);
				break;

			default:
				break;
		}
	};

	const handleTablePageEntiresChange = () => {
		switch (activeTabRef.current) {
			case activeAccounts.key:
				setactiveAccountPagination((prevState) => {
					return {
						...prevState,
						pagination: {
							...prevState.pagination,
							pageSize: dataCountToShow,
						},
					};
				});
				changeTablePage(activeAccounts.key, activeAccountPagination.pagination.current);
				break;
			case creatingAccounts.key:
				setcreatingAccountPagination((prevState) => {
					return {
						...prevState,
						pagination: {
							...prevState.pagination,
							pageSize: dataCountToShow,
						},
					};
				});
				changeTablePage(creatingAccounts.key, creatingAccountPagination.pagination.current);
				break;
			case needAttention.key:
				setNeedAttentionPagination((prevState) => {
					return {
						...prevState,
						pagination: {
							...prevState.pagination,
							pageSize: dataCountToShow,
						},
					};
				});
				changeTablePage(needAttention.key, needAttentionPagination.pagination.current);
				break;
			case deletedAccounts.key:
				setdeletedAccountPagination((prevState) => {
					return {
						...prevState,
						pagination: {
							...prevState.pagination,
							pageSize: dataCountToShow,
						},
					};
				});
				changeTablePage(deletedAccounts.key, deletedAccountPagination.pagination.current);
				break;
			default:
				break;
		}
	};

	const onSearch = async (searchValue) => {
		setSearchValue(searchValue);
		const queryParams = { ...accountType };
		const updatedSelectedFilterCount = { ...selectedFilterCount };
		// while doing search if we have any filter value will include in params
		Object.keys(filterData).forEach((key) => {
			const queryKey = queryParamFieldMapping[key];
			const selected = (Array.isArray(filterData[key]) && filterData[key]?.filter((data) => data?.isSelected)) || [];
			updatedSelectedFilterCount[key] = selected?.length;
			if (selected?.length > 0) {
				queryParams[queryKey] = selected
					.reduce((acc, data) => {
						acc.push(data[labelFieldMapping[key]]);
						return acc;
					}, [])
					.join(',');
			}
		});
		const data = { ...queryParams, pageNumber: 1 }; // page number will be 1 by default
		// only send search param when we have search value
		if (searchValue) {
			data.search = searchValue;
		}
		// updating page number before doing a search
		changePageNumber({ current: 1 });
		await getEmployeeDetails(getStatusForTab(selectedTab), data);
	};

	function handleFilterChange(updatedFilterData = {}) {
		let queryParams = { ...accountType };
		let updatedSelectedFilterCount = { ...selectedFilterCount };
		Object.keys(updatedFilterData).forEach((key) => {
			const queryKey = queryParamFieldMapping[key];
			const selected = (Array.isArray(updatedFilterData[key]) && updatedFilterData[key]?.filter((data) => data?.isSelected)) || [];
			updatedSelectedFilterCount[key] = selected.length;
			if (selected?.length > 0) {
				queryParams[queryKey] = selected
					.reduce((acc, data) => {
						acc.push(data[labelFieldMapping[key]]);
						return acc;
					}, [])
					.join(',');
			}
		});

		setSelectedFilterCount({ ...updatedSelectedFilterCount });
		updateTotalFilterCount(updatedSelectedFilterCount);

		let status = getStatusForTab(selectedTab);
		setFilterData({ ...updatedFilterData });
		// if we have any filter data present will store in active filter state and used for page change function
		if (queryParams[queryParamFieldMapping.needAttentionStatuses]) {
			setActiveFilterValues(queryParams[queryParamFieldMapping.needAttentionStatuses]);
		}
		// while doing filter if we have any search value will include in params
		if (searchValue) {
			queryParams.search = searchValue;
		}
		// updating page number before doing a filter
		changePageNumber({ current: 1 });
		getEmployeeDetails(status, { ...queryParams });
	}

	function updateTotalFilterCount(selectedFilter) {
		let updatedFilterCount = Object.values(selectedFilter).reduce((value1, value2) => value1 + value2);
		setTotalFilterCount(updatedFilterCount);
	}

	function handleClearAllFilter(isSelected) {
		setActiveFilterValues(null); // clearing all active filter values while clear all
		let updatedFilterData = { ...filterData };
		let updatedSelectedFilterCount = { ...selectedFilterCount };
		if (updatedFilterData) {
			const filterDatas = Object.keys(updatedFilterData);
			filterDatas.forEach((key) => {
				const filterDataKey = (Array.isArray(updatedFilterData[key]) && updatedFilterData[key]) || [];
				filterDataKey.forEach((value) => {
					value.isSelected = false;
				});
				if (isSelected) {
					updatedSelectedFilterCount[key] = updatedFilterData[key].length;
				} else {
					updatedSelectedFilterCount[key] = 0;
				}
			});
		}
		updateTotalFilterCount(updatedSelectedFilterCount);
		setSelectedFilterCount({ ...updatedSelectedFilterCount });
		setFilterData({ ...updatedFilterData });
		// updating page number after clearing the filter
		changePageNumber({ current: 1 });
		changeTablePage(selectedTab, null, { clearAllFilter: true });
	}

	async function deleteEmployees() {
		setIsDataLoading(true);
		try {
			let response = await employeeApi.deleteManageEmployees([emp_Id]);
			if (response && response.message) {
				setSelectedEmployee([]);
				getEmployeeDetails();
				setpopupVisible(true);
				setPopupContent({
					title: response?.message,
					content: null,
				});
			}
			setIsDataLoading(false);
		} catch (err) {
			setIsDataLoading(false);
		}
	}
	const closePopup = () => {
		let queryParams = {
			status: 'ACTIVE',
			includeEmployeeCounts: true,
			pageSize: dataCountToShow,
			pageNumber: activeAccountPagination?.pagination?.current,
			payrollBlockCheck: false,
		};
		setpopupVisible(false);
		fetchEmployees(queryParams);
	};
	const showModal = (val, val1) => {
		setEmp_Id(val1?.employeeCode);
		setVisible(true);
	};
	const closeModal = () => {
		setVisible(false);
	};
	const afterOpenModal = (e) => {
		deleteEmployees();
		setVisible(false);
	};

	const handleEmpToPortalEmail = (event) => {
		setMailState({ ...mailState, emailId: event });
	};

	const handleEmpToPortal = () => {
		mailState?.emailId && props.emailDataSaga(mailState);
		(!mailState?.isEmail || (mailState?.isEmail && mailState?.isEmail !== mailState?.emailId)) && props.updateEmailSaga(mailState);
	};
	const handleForCancel = () => {
		setMailState({
			...mailState,
			mobileNumber: '',
			employeeCode: '',
			emailId: '',
			isEmail: '',
			employeeName: '',
			employeeId: '',
			portalInviteStatus: '',
		});
		props.invitePortalPopup(false);
	};
	return (
		<>
			<PopupComponent
				{...{
					visible,
					isDataLoading,
					closeModal,
					afterOpenModal,
					popupVisible,
					closePopup,
					popupContent,
					forPopup: props.isInvitePortalPopup,
					handleEmpToPortalEmail,
					setMailState,
					mailState,
					isInviteLoading: props.isEmailDataLoading,
					handleEmpToPortal,
					handleForCancel,
					subscription,
					setSubscription,
					confirmationModelOpen,
					afterConfirmationOpenModal,
					confirmationsRequest,
					cardloading,
					address,
					filedValue: mailState.emailId,
					kycStatus,
				}}
			/>
			<ManageEmployeePresentational
				{...{
					columns: columns,
					dataSource: employeeTableData,
					totalEmpSelect: selectedEmployeeLists.length,
					handleCheckBox,
					handlepassdata1: handlepassdata,
					employetableSelect,
					employetableNotSelect,
					selectedEmployees,
					setEmployeeTableData,
					employeeCount,
					handleTabChange,
					isDataLoading,
					handleFilterChange,
					filterData,
					setFilterData,
					handleClearAllFilter,
					handleDelete: deleteEmployees,
					isData,
					selectedEmployee,
					selectedFilterCount,
					totalFilterCount,
					selectedTab,
					enableFooter,
					enableCutOff,
					activeAccountPagination,
					creatingAccountPagination,
					needAttentionPagination,
					deletedAccountPagination,
					handlePaginationChange,
					totalCount,
					employeeListForDownload,
					onSearch,
					getEmployeeDetailsForDownload,
					buttonRef,
					activeTabs,
					isSubcribed,
					userNavigationData: props.userNavigationData,
					subscription,
					setSubscription,
					employeeDownloadList: props.employeeDownloadList,
					isEmployeeNewDataLoading: props.isEmployeeNewDataLoading,
					userNavigationMenu: props.userNavigationMenu,
					setDataCountToShow,
					dataCountToShow,
				}}
			/>
		</>
	);
};
const mapStateToProps = (state) => {
	return {
		isEmailDataLoading: Selectors.isEmailDataLoading(state),
		emailData: Selectors.emailDataSave(state),
		isInvitePortalPopup: Selectors.isInvitePortalPopup(state),
		currentUser: SelectorsApp.currentUser(state),
		userNavigationData: SelectorsApp.userNavigationData(state),
		newEmployeeData: Selectors.newEmployeeData(state),
		isEmployeeNewDataLoading: Selectors.isEmployeeNewDataLoading(state),
		inviteCardStatus: Selectors.inviteCardStatus(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		emailDataSaga: (mailState) => dispatch(Actions.creators.postEmailData(mailState)),
		updateEmailSaga: (mailState) => dispatch(Actions.creators.updateEmailNewStart(mailState)),
		invitePortalPopup: (flag) => dispatch(Actions.creators.invitePortalPopup(flag)),
		inviteCardStatusSaga: (status) => dispatch(Actions.creators.inviteCardStatus(status)),
		userNavigationMenu: () => dispatch(action.creators.getUserNavigation()),
		employeeDownloadList: () => dispatch(Actions.creators.getEmployeeNewData()),
		userNavigationMenu: () => dispatch(Action.creators.getUserNavigation()),
	};
};
const Connected = connect(mapStateToProps, mapDispatchToProps)(ManageEmployeeFunctional);
const ReducedScreen = withReducer('EmailReducer', reducer, sagas)(Connected);
export default withRouter(ReducedScreen);
