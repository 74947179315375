import React, { useEffect, useState } from 'react';
import { EMPLOYEE_POPUP_CONSTANT, popUpNotification } from '@constants/app-constants';
import SubscriptionPopup from '@sharedComponent/alert-notification/subscriptionPopup';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import Inputbox from '@pages/DesignSystem/Input-Field';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';

const InviteToPortal = ({ onAccept, onReject, onChange, loading, value }) => {
	const [_isDisabled, setIsDisabled] = useState(true);
	const [_value, setValue] = useState('');
	useEffect(() => {
		if (typeof value === 'string') {
			setValue(value);
		}
	}, [value]);

	return (
		<div className="invite_to_portal_popup_wrapper">
			<p className="title">Enter Employee Email</p>
			<ul className="sub_title_contents">
				<li>
					{' '}
					<p>They will be able to request leaves, download their play-slips and read announcements.</p>{' '}
				</li>
				<li>
					{' '}
					<p>They will not have admin access.</p>{' '}
				</li>
			</ul>
			<Inputbox
				name="email"
				type="email"
				label="Add Employee Email"
				value={_value}
				placeholder="johndoe@company.com"
				onChange={(event) => {
					onChange(event.target.value);
					setValue(event.target.value);
					if (event.target.value && typeof event.target.value === 'string' && event.target.value.trim().length > 2) {
						setIsDisabled(false);
					} else {
						setIsDisabled(true);
					}
				}}
			/>
			<div className="buttons_wrapper">
				<EdenredButtons
					onClick={() => {
						if (typeof onReject === 'function') onReject();
					}}
					btnType="secondary">
					Cancel
				</EdenredButtons>
				<EdenredButtons
					loading={loading}
					disabled={_isDisabled}
					onClick={() => {
						if (typeof onAccept === 'function') onAccept();
					}}>
					Invite
				</EdenredButtons>
			</div>
		</div>
	);
};

const PopupComponent = ({
	visible,
	closeModal,
	closePopup,
	afterOpenModal,
	popupVisible,
	popupContent,
	isInviteLoading,
	handleEmpToPortal,
	forPopup,
	mailState,
	handleEmpToPortalEmail,
	handleForCancel,
	setSubscription,
	subscription,
	confirmationModelOpen,
	afterConfirmationOpenModal,
	confirmationsRequest,
	cardloading,
	address,
	filedValue,
	kycStatus,
}) => {
	const [replacementModal, setRaceplacementModal] = useState(false);
	const handleClose = () => {
		setRaceplacementModal(false);
		afterConfirmationOpenModal();
	};
	return (
		<div>
			<Edenredmodal
				{...{
					title: 'Do You Want To delete this Employee?',
					desc: EMPLOYEE_POPUP_CONSTANT?.CARD_REMOVE_MSG_WEB,
					onOkay: () => afterOpenModal(),
					onCancelButton: 'No',
					onOkayButton: 'Yes',
					onCancel: () => closeModal(),
					isOpen: visible,
					close: () => closeModal(),
				}}
			/>
			<Edenredmodal
				{...{
					title: popupContent.title,
					desc: popupContent.content || '',
					onOkayButton: popupContent.onOkayButton || 'Ok',
					onOkay: () => closePopup(false),
					isOpen: popupVisible,
					close: () => closePopup(false),
					textcenter: true,
				}}
			/>

			<Edenredmodal
				isOpen={forPopup}
				content={
					<InviteToPortal
						onAccept={handleEmpToPortal}
						onReject={handleForCancel}
						onChange={handleEmpToPortalEmail}
						loading={isInviteLoading}
						value={filedValue}
					/>
				}
			/>
			<SubscriptionPopup
				visible={subscription}
				message={popUpNotification.saveMessage}
				subMessage={popUpNotification?.subSaveMessage}
				className="payroll-save-popup"
				okText="Know More"
				cancelText="Cancel"
				setSubscription={setSubscription}
			/>
			<Edenredmodal
				title="This card was blocked due to missing or expired Emirates ID"
				onOkay={() => afterConfirmationOpenModal()}
				onOkayButton="Got It"
				onCancel={() => setRaceplacementModal(true)}
				onCancelButton="I still want to replace"
				isOpen={kycStatus && confirmationModelOpen}
				loading={cardloading}
				children={
					<p
						style={{
							marginBottom: '0px',
							marginTop: '7px',
							fontFamily: 'Montserrat-Medium',
							fontWeight: 300,
							fontSize: '16px',
							textAlign: 'center',
							color: '#737780',
						}}>
						{`The card will be unblocked after the Emirates ID is updated by ${mailState?.employeeName} on the C3pay app`}
					</p>
				}
				close={() => afterConfirmationOpenModal()}
			/>
			<Edenredmodal
				{...{
					title: `${EMPLOYEE_POPUP_CONSTANT?.REPLACE_CARD} ${mailState?.employeeName}?`,
					onOkay: () => confirmationsRequest(),
					onCancelButton: 'No',
					onOkayButton: 'Yes',
					onCancel: () => (kycStatus ? handleClose() : afterConfirmationOpenModal()),
					isOpen: kycStatus ? replacementModal : confirmationModelOpen,
					close: () => (kycStatus ? handleClose() : afterConfirmationOpenModal()),
					loading: cardloading,
					children: (
						<>
							<p
								style={{
									marginTop: '7px',
									fontFamily: 'Montserrat-Medium',
									fontWeight: 300,
									fontSize: '14px',
									textAlign: 'center',
									color: '#737780',
								}}>
								{EMPLOYEE_POPUP_CONSTANT?.REPLACE_CARD_ADD}
							</p>{' '}
							<p
								style={{
									marginBottom: '0px',
									marginTop: '7px',
									fontFamily: 'Montserrat-Medium',
									fontWeight: 300,
									fontSize: '14px',
									textAlign: 'center',
									color: '#737780',
								}}>
								{address?.address2},{address?.address3}
							</p>
						</>
					),
				}}
			/>
		</div>
	);
};

export default PopupComponent;
