import Axios from 'axios';
import { message } from 'antd';
import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import { call, put, takeEvery } from 'redux-saga/effects';
import Actions from '../invoiceDetailAction';
import moment from 'moment';

export function* downloadCompanyStatementService() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/Invoice/${corporateId}/SOA/${moment().year()}`;
		const res = yield call(Axios.get, uri, {
			responseType: 'blob',
			headers: {
				Accept: 'application/xlsx',
			},
		});

		const BACKEND_SUCCESS_MSG_FOR_EMPTY = 'text/plain';

		if (res.data.type !== BACKEND_SUCCESS_MSG_FOR_EMPTY) {
			const downloadUrl = window.URL.createObjectURL(new Blob([res.data], {}));
			const link = document.createElement('a');
			link.href = downloadUrl;
			link.setAttribute('download', `Invoice SOA.xlsx`); //any other extension
			document.body.appendChild(link);
			link.click();
			link.remove();
			yield put(Actions.creators.downloadCompanyStatementSuccess('Download Successfully'));
		} else {
			yield put(Actions.creators.downloadCompanyStatementSuccess('No Records Available for the Invoice for year'));
		}
	} catch (err) {
		yield put(Actions.creators.downloadCompanyStatementFail());
	}
}

export function* invoiceHistoryService() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/InvoiceReceipt/${corporateId}`;
		const res = yield call(Axios.get, uri);

		yield put(Actions.creators.invoiceHistorySuccess(res?.data?.invoiceReceipts));
	} catch (err) {
		yield put(Actions.creators.invoiceHistoryFail());
	}
}

export function* getGroupInvoiceService() {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/InvoiceReceipt/${corporateId}/group`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.getGroupInvoiceSuccess(res?.data));
	} catch (err) {
		yield put(Actions.creators.getGroupInvoiceFail());
	}
}

export function* saveGroupInvoiceService(action) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	const selectedCompaniesPayload = action?.selectedCompany?.map((company) => {
		return {
			corporateId: company.corporateId,
			amount: company.receiptAmount,
		};
	});
	const payload = {
		receiptId: action.receiptId,
		groupReceiptInvoice: selectedCompaniesPayload,
	};
	try {
		const uri = `${DOMAIN_URL}/InvoiceReceipt/${corporateId}/create-group`;
		yield call(Axios.post, uri, payload);
		yield put(Actions.creators.saveGroupInvoiceSuccess('Saved Successfully.'));
	} catch (err) {
		yield put(Actions.creators.saveGroupInvoiceFail('Error in Save'));
	}
}

export function* invoiceReceiptDownloadService(action) {
	const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
	try {
		const uri = `${DOMAIN_URL}/InvoiceReceipt/${corporateId}/${action.receiptId}/Download`;
		const res = yield call(Axios.get, uri, { responseType: 'blob' });
		yield put(Actions.creators.invoiceReceiptDownloadSuccess(res.data));
	} catch (err) {
		yield put(Actions.creators.invoiceReceiptDownloadFail());
	}
}

export function* viewAllInvoicesData(action) {
	try {
		yield put(Actions.creators.allInvoicesLoading(true));
		const { pageNumber, pageSize } = action || {}
		const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
		const uri = `${DOMAIN_URL}/Invoice/${corporateId}?pageNumber=${pageNumber || 1}&pageSize=${pageSize || 10}`;
		const res = yield call(Axios.get, uri);
		yield put(Actions.creators.viewAllInvoices(res?.data));
		yield put(Actions.creators.allInvoicesLoading(false));
	} catch (err) {
		yield put(Actions.creators.allInvoicesLoading(false));
		console.error(err)
	}
}

export function* downloadInvoice(action) {
	try {
		yield put(Actions.creators.downloadInvoiceLoading(true));
		const corporateId = localStorage.getItem(APP_VARIABLES.CORPORATE_ID);
		const corporateName = localStorage.getItem(APP_VARIABLES.CORPORATE_NAME);
		const uri = `${DOMAIN_URL}/Invoice/${corporateId}/download?id=${action.id}`;
		const res = yield call(Axios.get, uri, {
			responseType: 'blob',
		});
		const responseType = res.data?.type || ''
		if (responseType === 'application/pdf') {
			// Create the Blob URL and trigger the download
			const downloadUrl = window.URL.createObjectURL(res.data);
			const link = document.createElement('a');
			link.href = downloadUrl;
			link.download = `${corporateName}-${action.invoiceNumber}.pdf`;
			document.body.appendChild(link);
			link.click();
			link.remove();
			// Revoke the Blob URL to release memory
			window.URL.revokeObjectURL(downloadUrl);
		} else if (responseType === 'text/plain') {
			const textResponse = yield res.data.text() // getting the text message from response
			message.warn(String(textResponse))
		} else {
			message.error('Invalid file format')
		}
		yield put(Actions.creators.downloadInvoiceLoading(false));
	} catch (err) {
		console.error(err)
		yield put(Actions.creators.downloadInvoiceLoading(false));
	}
}

export default function* invoiceWatcher() {
	yield takeEvery(Actions.types.DOWNLOAD_COMPANY_STATEMENT_START, downloadCompanyStatementService);

	yield takeEvery(Actions.types.INVOICE_HISTORY_START, invoiceHistoryService);

	yield takeEvery(Actions.types.INVOICE_RECEIPT_DOWNLOAD_START, invoiceReceiptDownloadService);

	yield takeEvery(Actions.types.GET_GROUP_INVOICE_START, getGroupInvoiceService);

	yield takeEvery(Actions.types.SAVE_GROUP_INVOICE_START, saveGroupInvoiceService);
	yield takeEvery(Actions.types.VIEW_ALL_INVOICES_START, viewAllInvoicesData);
	yield takeEvery(Actions.types.DOWNLOAD_INVOICE, downloadInvoice);
}
