import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { icons } from '@assets/icons';
import { images } from '@assets/img';
import Pagination from '@pages/DesignSystem/Table/Pagination';
import Table from '@pages/DesignSystem/Table/Table';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import ImageComponent from '@sharedComponent/image-component';
import { useHistory } from 'react-router-dom';
import Chips from '@pages/DesignSystem/Chips';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../invoiceDetailAction';
import { entriesDropDown, formatDateFromInput } from '@helpers';
import MultiSelect from '@pages/DesignSystem/Multiselect/MultiSelect';
import './viewInvoices.scss';

const ViewInvoices = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const { allInvoices, allInvoicesLoading, downloadInvoicesLoading } = useSelector((state) => state?.invoiceState) || {};
	const { invoiceData, totalCount } = allInvoices || {};
	const [invoiceId, setInvoiceId] = useState(null); // This invoice id is used to track the download of pdf loading in table

	useEffect(() => {
		dispatch(Actions.creators.viewAllInvoicesStart(pageNumber, pageSize));
	}, [pageNumber, pageSize]);

	const showStatus = (type = '') => {
		if (String(type).toLowerCase() === 'paid') {
			return <Chips color="#CCECE6">{type}</Chips>;
		} else if (String(type).toLowerCase() === 'pending') {
			return <Chips color="#FFBECB">{type}</Chips>;
		} else {
			return <Chips>{String(type) || '-'}</Chips>;
		}
	};

	const downloadStatus = (id, invoiceNumber) => {
		dispatch(Actions.creators.downloadInvoice(id, invoiceNumber));
	};

	const columns = [
		{
			sorter: (a, b) => a.date.localeCompare(b.date),
			key: 'date',
			title: 'Date of Invoice',
			width: '30%',
			dataIndex: 'date',
			sort: true,
			render: (val) => {
				return <p className="table-cell">{val ? formatDateFromInput(val, 'D MMM YYYY') : '-'}</p>;
			},
		},
		{
			sorter: (a, b) => a.status.localeCompare(b.status),
			key: 'status',
			width: '30%',
			title: 'Status',
			dataIndex: 'status',
			sort: true,
			render: (val) => {
				return <p className="table-cell status-chip">{showStatus(val)}</p>;
			},
		},
		{
			key: 'id',
			title: '',
			dataIndex: 'id',
			sort: true,
			render: (val, data) => {
				return (
					<p className="table-cell">
						<EdenredButtons
							className="download-invoice-btn"
							btnType="secondary"
							loading={invoiceId === val && downloadInvoicesLoading}
							onClick={() => {
								setInvoiceId(val);
								downloadStatus(val, data.invoiceNumber);
							}}
							leftIcon={<ImageComponent src={icons.focus_state.downloadAttachement} />}>
							Download invoice
						</EdenredButtons>
					</p>
				);
			},
		},
	];
	const goBack = () => () => {
		history.goBack();
	};
	const handlePageChange = (page) => {
		setPageNumber(page);
	};
	return (
		<div className="view-all-invoices-container">
			<Row>
				<Col data-testid="test-view-all-invoices-header" className="view-all-invoices-header">
					<ETypo bold h2>
						All Invoices
					</ETypo>
					<EdenredButtons
						onClick={goBack()}
						className="go-back-btn"
						btnType="secondary"
						leftIcon={<ImageComponent src={images.leftsarrow} />}>
						Back
					</EdenredButtons>
				</Col>
			</Row>
			<Row className="view-all-invoices-table" style={{ rowGap: '10px' }}>
				<div className="page-entries-container">
					<div className="page-entries">
						<ETypo b2 light color="#484B52" style={{ paddingRight: '10px' }}>
							Show
						</ETypo>
						<MultiSelect
							{...{
								Option: entriesDropDown,
								value: pageSize,
								dropDownValue: 'value',
								searchVisibility: false,
								onChange: (size) => setPageSize(size.value),
							}}
						/>
						<ETypo b2 light color="#484B52" style={{ paddingLeft: '10px' }}>
							Entries
						</ETypo>
					</div>
				</div>
				<Table columns={columns} data={invoiceData} tableLoading={allInvoicesLoading} />
				<div className="table_pagination_container">
					<div className="pagination_count">
						<ETypo light small color="#484B52">
							Showing {invoiceData?.length} out of {totalCount || 0} Invoices
						</ETypo>
					</div>
					<Pagination
						{...{
							currentPage: pageNumber,
							totalCount,
							pageSize,
							className: 'table_pagination',
							onPageChange: (page) => handlePageChange(page),
						}}
					/>
				</div>
			</Row>
		</div>
	);
};

export default ViewInvoices;
